import {round, dynamicSortMultiple} from '@/snippets/allSnippets'
import SalarieService from '@/services/SalarieService'
import EmployeeService from '@/services/EmployeeService'

async function freeRAD1 (companyid,fromDate, toDate, typeId, userId) {

  const newObj = {}
  const empF =[]
  const mainQuery = {}
  let eemp = null
  const filEmp = []
  const retObject = {}
  
  mainQuery.CompanyId = companyid
  await EmployeeService.index(mainQuery)
      .then(res => {
        if (res.data && res.data.employees) {
          eemp = res.data.employees
        }
      })
      .catch (err => {
        // eslint-disable-next-line
        console.log(err)
      })

  if (!eemp.length) {
    return empF
  }
  let haveError = false
  if (eemp.length > 0) {
    eemp.map(emp => {
      console.log('emp', emp)
      const endDate = emp.EndDateInCompany !== undefined &&  emp.EndDateInCompany !== null? new Date(emp.EndDateInCompany) : null
      // left company before endDate
      // if (endDate & endDate < toDate) {
      //   haveError = true
      // }
      // const startDate = emp.StartInCompany !== undefined &&  emp.StartInCompany !== null ? new Date(emp.StartInCompany) : null
      // // start in company after Start date
      // if (startDate & startDate > fromDate) {
      //   haveError = true
      // }
      // if (!emp.activ) {
      //   haveError = true
      // }
      // if(emp.black) {
      //   haveError = true
      // }
      // if(!emp.Gender) {
      //   haveError = true
      // }
      // if (userId && emp.id !== userId) {
      //   haveError = true
      // }
      if (haveError === false) {
        filEmp.push(emp)
      }
    })
  }

  // do filter on emp. ==>> only 
  // newObj.fromDate = '2024-01-01' 
  // newObj.toDate = '2024-12-31'
  // newObj.typeId = 1
  newObj.CompanyId = companyid
  newObj.fromDate = fromDate
  newObj.toDate = toDate
  newObj.typeId = typeId
  newObj.userId = userId && userId.id ? userId.id : null

  await SalarieService.choosedetail(newObj)
    .then(res => {
      const salH = res && res.data && res.data.salaries ? res.data.salaries : null

      let godBrutoM = 0
      let godBrutoZ = 0
      let godNetoM = 0
      let godNetoZ = 0
      let brM = 0
      let brZ = 0 

      let diplomskiBruto = 0
      let diplomskiNeto = 0
      let diplomskiBroj = 0

      let prijedipBruto = 0
      let prijedipNeto = 0
      let prijedipBroj = 0

      let sssBruto = 0
      let sssNeto = 0
      let sssBroj = 0

      let nizestrucnoBruto = 0
      let nizestrucnoNeto = 0
      let nizestrucnoBroj = 0

      let visokokvBruto = 0
      let visokokvNeto = 0
      let visokokvBroj = 0

      let srednjastrukBruto = 0
      let srednjastrukNeto = 0
      let srednjastrukBroj = 0

      let priuceniBruto = 0
      let priuceniNeto = 0
      let priuceniBroj = 0

      let nekvalBruto = 0
      let nekvalNeto = 0
      let nekvalBroj = 0

      filEmp.map(em => {
        
        console.log('imam em', em, em.id, em.DateOfBirht)

        if (salH) {
          salH.map(head => { 
            if (head.SalarieDetails && head.SalarieDetails.length > 0) {

              head.SalarieDetails.map(det => {
                if (det.empID === em.id) {
                  console.log('3 em.Gender', em.Gender, ' em.VatID ', em.VatID, ' det.VatID', det.VatID)
                  if (em.Gender !== undefined && em.Gender !== null && em.Gender === 'M') {
                    godBrutoM += round(det.FinBruto2 ,2)
                    godNetoM += round(det.FinNeto2 ,2)
                    brM +=1
                  }
                  if (em.Gender !== undefined && em.Gender !== null  && em.Gender === 'Z') {
                    godBrutoZ += round(det.FinBruto2 ,2)
                    godNetoZ += round(det.FinNeto2 ,2)
                    brZ +=1
                  }
                  const obraz = em.Obrazovanje ? parseInt(em.Obrazovanje) : 0
                  if(obraz === 1 || obraz === 2) {
                    diplomskiBruto += round(det.FinBruto2 ,2)
                    diplomskiNeto += round(det.FinNeto2 ,2)
                    diplomskiBroj +=1
                  }
                  if(obraz === 3 || obraz === 4) {
                    prijedipBruto += round(det.FinBruto2 ,2)
                    prijedipNeto += round(det.FinNeto2 ,2)
                    prijedipBroj +=1
                  }
                  if(obraz === 5 || obraz === 6) {
                    sssBruto += round(det.FinBruto2 ,2)
                    sssNeto += round(det.FinNeto2 ,2)
                    sssBroj +=1
                  }
                  if(obraz === 7) {
                    srednjastrukBruto += round(det.FinBruto2 ,2)
                    srednjastrukNeto += round(det.FinNeto2 ,2)
                    srednjastrukBroj +=1
                  }
                  if(obraz === 8) {
                    priuceniBruto += round(det.FinBruto2 ,2)
                    priuceniNeto += round(det.FinNeto2 ,2)
                    priuceniBroj +=1
                  }
                  if(obraz === 9 || obraz === 10) {
                    nekvalBruto += round(det.FinBruto2 ,2)
                    nekvalNeto += round(det.FinNeto2 ,2)
                    nekvalBroj +=1
                  }
                }
              })
            }
          })
        }

      })

      const table1 = {}
      table1.total1 = {}
      table1.total1.bruto = Math.round(godBrutoM) + Math.round(godBrutoZ)
      table1.total1.neto = Math.round(godNetoM) + Math.round(godNetoZ)
      table1.total1.nr = Math.round(brM) + Math.round(brZ)

      table1.men = {}
      table1.men.bruto = Math.round(godBrutoM)
      table1.men.neto = Math.round(godNetoM)
      table1.men.nr = Math.round(brM)

      table1.women = {}
      table1.women.bruto = Math.round(godBrutoZ)
      table1.women.neto = Math.round(godNetoZ)
      table1.women.nr = Math.round(brZ)
      
      table1.total2 = {}
      table1.total2.bruto = Math.round(diplomskiBruto) + Math.round(prijedipBruto) + Math.round(sssBruto) + Math.round(nizestrucnoBruto) + Math.round(visokokvBruto) + Math.round(srednjastrukBruto) + Math.round(priuceniBruto) + Math.round(nekvalBruto)
      table1.total2.neto = Math.round(diplomskiNeto) + Math.round(prijedipNeto) + Math.round(sssNeto) + Math.round(nizestrucnoNeto) + Math.round(visokokvNeto) + Math.round(srednjastrukNeto) + Math.round(priuceniNeto) + Math.round(nekvalNeto)
      table1.total2.nr = Math.round(diplomskiBroj) + Math.round(prijedipBroj) + Math.round(sssBroj) + Math.round(nizestrucnoBroj) + Math.round(visokokvBroj) + Math.round(srednjastrukBroj) + Math.round(priuceniBroj) + Math.round(nekvalBroj)

      table1.diplomskiBruto = Math.round(diplomskiBruto)
      table1.diplomskiNeto = Math.round(diplomskiNeto)
      table1.diplomskiBroj = Math.round(diplomskiBroj)

      table1.prijedipBruto = Math.round(prijedipBruto)
      table1.prijedipNeto = Math.round(prijedipNeto)
      table1.prijedipBroj = Math.round(prijedipBroj)

      table1.sssBruto = Math.round(sssBruto)
      table1.sssNeto = Math.round(sssNeto)
      table1.sssBroj = Math.round(sssBroj)

      table1.nizestrucnoBruto = Math.round(nizestrucnoBruto)
      table1.nizestrucnoNeto = Math.round(nizestrucnoNeto)
      table1.nizestrucnoBroj = Math.round(nizestrucnoBroj)

      table1.visokokvBruto = Math.round(visokokvBruto)
      table1.visokokvNeto = Math.round(visokokvNeto)
      table1.visokokvBroj = Math.round(visokokvBroj)

      table1.srednjastrukBruto = Math.round(srednjastrukBruto)
      table1.srednjastrukNeto = Math.round(srednjastrukNeto)
      table1.srednjastrukBroj = Math.round(srednjastrukBroj)

      table1.priuceniBruto = Math.round(priuceniBruto)
      table1.priuceniNeto = Math.round(priuceniNeto)
      table1.priuceniBroj = Math.round(priuceniBroj)

      table1.nekvalBruto = Math.round(nekvalBruto)
      table1.nekvalNeto = Math.round(nekvalNeto)
      table1.nekvalBroj = Math.round(nekvalBroj)

      retObject.table1 = {...table1}

      retObject.table2 = {...table1}
      
    })
    .catch(err => {
      console.log('no salaries ', err)
      return err
    })
    return retObject
}

export {freeRAD1}

